import axios from "axios";


import Vue from "vue";

import GoogleTranslateSelect from '@google-translate-select/vue2';
import VGoogleTranslate from "v-google-translate";
import contenteditable from "vue-contenteditable";
import CountryFlag from 'vue-country-flag';
import Notifications from "vue-notification";
import VueSimpleAlert from "vue-simple-alert";
import VueSvgGauge from "vue-svg-gauge";
import VueTheMask from "vue-the-mask";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";
import router from "./routes";
import languages from "./utils/languages";
import {FormatDolar,numberFormater} from "./utils/utils.js";

Vue.use(VGoogleTranslate)
Vue.use(GoogleTranslateSelect)

Vue.component('country-flag', CountryFlag)

import "primeicons/primeicons.css";


import * as VueGoogleMaps from "vue2-google-maps";
// import VueRecord from '@codekraft-studio/vue-record'

import VueHtmlToPaper from "vue-html-to-paper";


Vue.config.productionTip = false;
Vue.prototype.$user = JSON.parse(localStorage.getItem("user"));

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          console.log('Service Worker registrado con éxito:', registration);
        })
        .catch(error => {
          console.error('Error al registrar el Service Worker:', error);
        });
    });
  }

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

const instanceSimple = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

const instance_products = axios.create({
    baseURL: process.env.VUE_APP_PRODUCTS_API_URL
});

const instance_products_simple = axios.create({
    baseURL: process.env.VUE_APP_PRODUCTS_API_URL
});

const instance_financial = axios.create({
    baseURL: process.env.VUE_APP_FINANCIAL_API_URL
});

const instance_financial_simple = axios.create({
    baseURL: process.env.VUE_APP_FINANCIAL_API_URL
});

var USER;
var NOTIFICATIONS = 0;
var POSTS_TOTAL = 0;
var WORDS;

let token = localStorage.getItem("api_token");
if (token) {
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    instance_products.defaults.headers.common["Authorization"] =
        "Bearer " + token;

    instance_financial.defaults.headers.common["Authorization"] =
        "Bearer " + token;

    var data = JSON.parse(localStorage.getItem("user"));

    if (localStorage.getItem("words") != 'undefined') {
        var wordsData = JSON.parse(localStorage.getItem("words"));

        if (wordsData) {
            WORDS = wordsData;
        }
    }


    if (data.user) {
        USER = data.user;

        instance.defaults.baseURL += "/" + USER.type;
        instance_products.defaults.baseURL += "/" + USER.type;
        instance_financial.defaults.baseURL += "/" + USER.type;

        if (USER.type == "office") {
            instance.defaults.baseURL += "/" + USER.operation;
            instance_products.defaults.baseURL += "/" + USER.operation;
            instance_financial.defaults.baseURL += "/" + USER.operation;
        }
    }
    var notifications = JSON.parse(localStorage.getItem("notifications"));

    if (notifications) {
        NOTIFICATIONS = notifications;
    }

    var posts_total = JSON.parse(localStorage.getItem("posts_total"));
    if (posts_total) {
        POSTS_TOTAL = posts_total;
    }
}

Vue.config.productionTip = false;
// Vue.prototype.$unity = UNITY;
Vue.prototype.$languages = languages;
Vue.prototype.$words = WORDS;
Vue.prototype.$http = instance;
Vue.prototype.$http_products = instance_products;
Vue.prototype.$http_products_simple = instance_products_simple;
Vue.prototype.$http_financial = instance_financial;
Vue.prototype.$http_financial_simple = instance_financial_simple;
Vue.prototype.$httpAll = instanceSimple;
Vue.prototype.$user = USER;
Vue.prototype.$notifications = NOTIFICATIONS;
Vue.prototype.$posts_total = POSTS_TOTAL;
Vue.prototype.$selects = [];

Vue.prototype.$numberFormater = numberFormater;
Vue.prototype.$formatDolar = FormatDolar;

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDkOVSw5zJzLcTS5nmHyb1iffsHP8fv13M",
        // libraries: 'places'
    },
    installComponents: true,
});

Vue.component("RecursiveList", () =>
    import ("./components/RecursiveList.vue"));
Vue.component("ImageList", () =>
    import ("./components/ImageList.vue"));

Vue.component("RecursiveListContacts", () =>
    import ("./components/RecursiveListContacts.vue")
);

Vue.component("Layout", () =>
    import ("./components/Layout.vue"));

Vue.component("Layout-mobile", () =>
    import ("./components/Layouts/LayoutMob.vue")
);

Vue.component("Layout-new-mob", () =>
    import ("./components/NewLayoutMob.vue"));

Vue.component("layout-seller", () =>
    import ("./components/Layouts/Sellers.vue")
);

Vue.component("layout-seller-academy", () =>
    import ("./components/Layouts/SellersAcademy.vue")
);

Vue.component("layout-academy", () =>
    import ("./components/Layouts/Academy.vue")
);

Vue.component("Upload", () =>
    import ("./components/Upload.vue"));

Vue.component("UploadSmall", () =>
    import ("./components/UploadSmall.vue"));

Vue.component("upload-image", () =>
    import ("./components/ImageUpload.vue"));

Vue.component("upload-video", () =>
    import ("./components/VideoUpload.vue"));

Vue.component("photo", () =>
    import ("./components/inputImageUpload.vue"));

Vue.component("item-status", () =>
    import ("./components/itemStatus.vue"));

Vue.component("photo-custom", () =>
    import ("./components/inputImageUploadCustom.vue")
);

Vue.component("from-address", () =>
    import ("./components/Address")
);


Vue.component("select-product", () =>
    import ("./pages/Products/select.vue"));

Vue.component("icon", () =>
    import ("./components/inputImageUploadAcademy.vue"));

Vue.component("file-academy", () =>
    import ("./components/inputFileUploadAcademy.vue")
);

Vue.component("input-academy", () =>
    import ("./components/inputFile.vue"));

Vue.component("file", () =>
    import ("./components/inputFileUpload.vue"));

Vue.component("app-avatar", () =>
    import ("./components/avatar.vue"));

// Vue.component("tasks-list", () =>
//     import ("./components/Tasks"));

// Vue.component("tasks-card", () =>
//     import ("./components/Tasks/old/card.vue"))

// Vue.component("tasks-form", () =>
//     import ("./components/Tasks/old/form.vue"));

Vue.component("units-select", () =>
    import ("./components/User/units.vue"));
Vue.component("number-field", () =>
    import ("./components/number-field.vue"));
Vue.component("number-field-no-border", () =>
    import ("./components/number-field-no-border.vue")
);

Vue.component("post", () =>
    import ("./components/Post"));

Vue.component("post-media", () =>
    import ("./components/postMedia.vue"));

Vue.component("navigation", () =>
    import ("./components/BottomNavigation.vue"));

Vue.component("navigation-seller", () =>
    import ("./components/BottomSellerNavigation.vue")
);

Vue.component("notification", () =>
    import ("./components/notification.vue"));

Vue.component("user-list", () =>
    import ("./components/User"));

Vue.component("user-form", () =>
    import ("./components/User/form.vue"));

Vue.component("units-select", () =>
    import ("./components/User/units.vue"));

Vue.component("user-view", () =>
    import ("./components/User/view.vue"));

Vue.component("franchises-select", () =>
    import ("./components/User/franchises.vue")
);

Vue.component("master-franchises-select", () =>
    import ("./components/User/master_franchises.vue")
);
Vue.component("franchise-units-select", () =>
    import ("./components/User/franchise_units.vue")
);

Vue.component("unity-list", () =>
    import ("./components/Unity"));

Vue.component("unity-recover-list", () =>
    import ("./components/Unity/recover.vue")
);

Vue.component("unity-user-list", () =>
    import ("./components/Unity/users.vue"));

Vue.component("unity-user-recover-list", () =>
    import ("./components/Unity/users_recover.vue")
);

Vue.component("unity-form", () =>
    import ("./components/Unity/form.vue"));

Vue.component("sale-list", () =>
    import ("./components/Sale"));

Vue.component("sale-form", () =>
    import ("./components/Sale/form.vue"));

Vue.component("goal-list", () =>
    import ("./components/Goal"));

Vue.component("goal-view", () =>
    import ("./components/Goal/view.vue"));

Vue.component("goal-view-ranking", () =>
    import ("./components/Goal/view_ranking.vue")
);

Vue.component("goal-user", () =>
    import ("./components/Goal/user.vue"));

Vue.component("goal-form", () =>
    import ("./components/Goal/form.vue"));

Vue.component("award-list", () =>
    import ("./components/Award"));

Vue.component("award-form", () =>
    import ("./components/Award/form.vue"));

Vue.component("call-list", () =>
    import ("./components/Call"));

Vue.component("call-form", () =>
    import ("./components/Call/form.vue"));

Vue.component("message-form", () =>
    import ("./pages/Admin/Message/form.vue"));

Vue.component("messages", () =>
    import ("./components/Message"));

Vue.component("calendar", () =>
    import ("./components/Calendar"));

Vue.component("top-message", () =>
    import ("./components/topMessage.vue"));


Vue.component("top-message-academy", () =>
    import ("./components/topMessageAcademy.vue")
);

Vue.component("my-profile", () =>
    import ("./components/Profile"));

Vue.component("my-earnings", () =>
    import ("./components/Earnings"));

Vue.component("my-sales", () =>
    import ("./components/MySales"));

Vue.component("my-sales2", () =>
    import ("./components/MySales/page2.vue"));

Vue.component("ranking", () =>
    import ("./components/Ranking"));

Vue.component("my-goals", () =>
    import ("./components/MyGoals"));

Vue.component("my-awards", () =>
    import ("./components/MyAwards"));

Vue.component("my-awards2", () =>
    import ("./components/MyAwards/page2.vue"));

Vue.component("my-catalog", () =>
    import ("./components/MyCatalog"));

Vue.component("my-clients", () =>
    import ("./components/MyClients"));

Vue.component("my-shopping", () =>
    import ("./components/MyShopping"));

Vue.component("my-talks", () =>
    import ("./components/Talks"));

Vue.component("my-academy", () =>
    import ("./components/Academy"));

Vue.component("my-academy2", () =>
    import ("./components/Academy/category"));

Vue.component("my-academy3", () =>
    import ("./components/Academy/lesson"));

Vue.component("scale", () =>
    import ("./components/Scale"));

Vue.component("scale-form", () =>
    import ("./components/Scale/form.vue"));

Vue.component("cash-flow", () =>
    import ("./components/CashFlow"));

Vue.component("cash-flow-list", () =>
    import ("./components/CashFlow/list.vue"));

Vue.component("cash-flow-clousing", () =>
    import ("./components/CashFlow/closing.vue")
);

Vue.component("country-list", () =>
    import ("./components/Countries"));

Vue.component("country-form", () =>
    import ("./components/Countries/form.vue"));

Vue.component("domains-list", () =>
    import ("./components/Domains"));

Vue.component("domains-form", () =>
    import ("./components/Domains/form.vue"));

Vue.component("domainscontracts-list", () =>
    import ("./components/DomainsContracts")
);

Vue.component("domainscontracts-form", () =>
    import ("./components/DomainsContracts/form.vue")
);

Vue.component("domainsowners-list", () =>
    import ("./components/DomainsOwners"));

Vue.component("domainsowners-form", () =>
    import ("./components/DomainsOwners/form.vue")
);

Vue.component("domainsoperators-list", () =>
    import ("./components/DomainsOperators")
);

Vue.component("domainsoperators-form", () =>
    import ("./components/DomainsOperators/form.vue")
);

Vue.component("companiestypes-list", () =>
    import ("./components/CompaniesTypes")
);

Vue.component("companiestypes-form", () =>
    import ("./components/CompaniesTypes/form.vue")
);

Vue.component("patent-list", () =>
    import ("./components/Patent"));

Vue.component("patentsdocs-list", () =>
    import ("./components/PatentsDocs"));

Vue.component("patentsdocs-form", () =>
    import ("./components/PatentsDocs/form.vue")
);

Vue.component("patent-form", () =>
    import ("./components/Patent/form.vue"));

Vue.component("brand-list", () =>
    import ("./components/Brand"));

Vue.component("brandsdocs-list", () =>
    import ("./components/BrandsDocs"));

Vue.component("brandsdocs-form", () =>
    import ("./components/BrandsDocs/form.vue")
);

Vue.component("brandsrates-list", () =>
    import ("./components/BrandsRates"));

Vue.component("brandsrates-form", () =>
    import ("./components/BrandsRates/form.vue")
);

Vue.component("brand-form", () =>
    import ("./components/Brand/form.vue"));

Vue.component("records-list", () =>
    import ("./components/Records"));

Vue.component("records-form", () =>
    import ("./components/Records/form.vue"));

Vue.component("medias-list", () =>
    import ("./components/Medias"));

Vue.component("medias-form", () =>
    import ("./components/Medias/form.vue"));

Vue.component("mediascounts-list", () =>
    import ("./components/MediasCounts"));

Vue.component("mediascounts-form", () =>
    import ("./components/MediasCounts/form.vue")
);

Vue.component("medias-contracts-list", () =>
    import ("./components/MediasContracts")
);

Vue.component("medias-contracts-form", () =>
    import ("./components/MediasContracts/form.vue")
);

Vue.component("medias-operators-list", () =>
    import ("./components/MediasOperators")
);

Vue.component("medias-operators-form", () =>
    import ("./components/MediasOperators/form.vue")
);

Vue.component("bombs-list", () =>
    import ("./components/Bomb"));

Vue.component("bomb-form", () =>
    import ("./components/Bomb/form.vue"));

Vue.component("network-list", () =>
    import ("./components/Ranking/network.vue"));

Vue.component("ranking-unity-list", () =>
    import ("./components/Ranking/unity.vue")
);

Vue.component("post-comments", () =>
    import ("./components/Post/comments.vue"));

Vue.component("company-list", () =>
    import ("./components/Companies"));

Vue.component("company-form", () =>
    import ("./components/Companies/form.vue"));

Vue.component("companies-operators-list", () =>
    import ("./components/CompanyOperators")
);

Vue.component("companies-operators-form", () =>
    import ("./components/CompanyOperators/form.vue")
);

Vue.component("companies-contracts-list", () =>
    import ("./components/CompanyContracts")
);

Vue.component("companies-contracts-form", () =>
    import ("./components/CompanyContracts/form.vue")
);

Vue.component("category-list", () =>
    import ("./components/Category"));

Vue.component("category-form", () =>
    import ("./components/Category/form.vue"));

Vue.component("department-list", () =>
    import ("./components/Department"));

Vue.component("department-form", () =>
    import ("./components/Department/form.vue")
);

Vue.component("function-list", () =>
    import ("./components/Function"));

Vue.component("function-form", () =>
    import ("./components/Function/form.vue"));

Vue.component("level-list", () =>
    import ("./components/Level"));

Vue.component("level-form", () =>
    import ("./components/Level/form.vue"));

Vue.component("modules-list", () =>
    import ("./components/Modules"));

Vue.component("modules-form", () =>
    import ("./components/Modules/form.vue"));

Vue.component("permissions-list", () =>
    import ("./components/Permissions"));

Vue.component("permission-form", () =>
    import ("./components/Permissions/form.vue")
);

Vue.component("product-attributes-list", () =>
    import ("./components/ProductsAttributes")
);

Vue.component("product-attributes-form", () =>
    import ("./components/ProductsAttributes/form.vue")
);

Vue.component("products-list", () =>
    import ("./components/Products"));

Vue.component("products-form", () =>
    import ("./components/Products/form.vue"));

Vue.component("products-language-list", () =>
    import ("./components/ProductsLanguage/")
);

Vue.component("products-language-form", () =>
    import ("./components/ProductsLanguage/form.vue")
);

Vue.component("products-prices-list", () =>
    import ("./components/ProductsPrices/")
);

Vue.component("products-prices-form", () =>
    import ("./components/ProductsPrices/form.vue")
);

Vue.component("products-photo-list", () =>
    import ("./components/ProductsPhoto")
);

Vue.component("products-photo-form", () =>
    import ("./components/ProductsPhoto/form.vue")
);

// Vue.component('products-price-list', () =>
//     import ('./components/ProductsPrice'));

// Vue.component('products-price-form', () =>
//     import ('./components/ProductsPrice/form.vue'));

Vue.component("language-list", () =>
    import ("./components/Language"));

Vue.component("language-form", () =>
    import ("./components/Language/form.vue"));

Vue.component("Meeting-form", () =>
    import ("./pages/Meeting/index.vue"));

Vue.component("MeetingDet-form", () =>
    import ("./pages/MeetingDet/index.vue"));

Vue.component("MeetingMedia-form", () =>
    import ("./pages/MeetingMedia/index.vue")
);

Vue.component("MeetingChat-form", () =>
    import ("./pages/MeetingChat/index.vue")
);

Vue.component("UsersList", () =>
    import ("./components/Group/ListUsers.vue"));

Vue.component("NewGroup-form", () =>
    import ("./components/Group/NewGroup.vue"));

Vue.component("child", () =>
    import ("./components/Loop/children.vue"));

Vue.component("parent", () =>
    import ("./components/Loop/parent.vue"));

Vue.component("contacts", () =>
    import ("./components/Contacts"));



Vue.component("acium-chat", () =>
    import ("./components/Chat/index.vue"));

Vue.component("blog-post", {
    props: ["title"],
    template: `<h3>{{ title }}</h3>`,
});

Vue.component("badge", () =>
    import ("./components/Layouts/Badge.vue"));

Vue.component("navigation-finance", () =>
    import("./components/BottomFinanceNavigation.vue")
  );

Vue.use(Notifications);

Vue.use(VueSvgGauge);

Vue.use(VueTheMask);
Vue.use(VueSimpleAlert);
Vue.use(contenteditable);
// Vue.use(VueRecord);
Vue.use(VueHtmlToPaper);

new Vue({
    router,
    vuetify,
    render: function(h) {
        return h(App);
    },
}).$mount("#app");